/**
 * ActionCreators
 */

import * as types from './types'
import { createActions } from 'redux-actions'

export const login =  
  createActions({
    [types.REQUEST]: (req) => ({
      req,
    }),
    [types.SUCCESS]: (res) => ({
      res,
    }),
    [types.FAILURE]: () => ({
    }),
  },
  {
    prefix: types.PREFIX,
  })
