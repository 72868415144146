import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
// 
import Login from '../containers/Login'
import { history } from '../store/history'
// 
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <ConnectedRouter history={ history }>
          <Switch>
            <Route exact path="/" component={ Login } />
            <Redirect to="/" />
          </Switch>
        </ConnectedRouter>
      </div>
    );
  }
}

export default App;
