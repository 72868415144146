import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
//
import { client } from '../../api'
//
import * as actions from './actions'

const debug = require('debug')('web:login:saga')

function* handleRequest(action) {
  try {
    const ret = yield call([client, client.login], action.payload.req) 

    yield put(actions.login.success(ret.data))
  } catch(e) {
    yield put(actions.login.failure(e))
    debug('Exception occured on handleRequest', e)
  }
}

export default function* () {
  yield takeLatest('LOGIN/REQUEST', handleRequest);
  //yield takeLatest('LOGIN/SUCCESS', handleSuccess);
}
