import React, { Component } from 'react'
import styles from './styles.css'
import { TextField } from '@material-ui/core'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { Error, LogoIcon } from '../../../../components'


/**
 */
export class Form extends Component {

  static defaultProps = {
    error: null,
    domain: '',
    username: '',
    password: '',
  }

  state = {
    data: {
      domain: this.props.domain,
      username: this.props.username,
      password: this.props.password,
    }
  }

  render() {
      return (
        <div className={ styles.LoginForm }>
          <form onSubmit={ this.handleSubmitForm }>
            <header>
              <LogoIcon height="14px" />Login
            </header>
            { (() => {
              if (this.props.error) {
                return (
                  <Error className={ styles.Error }>
                    { this.props.error }
                  </Error>
                )
              }
            })() }
            <div>
              <div className={ styles.Row }>
                <DomainField
                  onChange={
                    this.handleChange('domain')
                  }
                  value={ this.state.data.domain }
                /> 
              </div>
              <div className={ styles.Row }>
                <UsernameField 
                  onChange={
                    this.handleChange('username')
                  }
                  value={ this.state.data.username }
                />
              </div>
              <div className={ styles.Row }>
                <PasswordField
                  onChange={
                    this.handleChange('password')
                  }
                  value={ this.state.data.password }
                />
              </div>
            </div>
            <footer>
              <input className={ styles.Submit } type="submit" value="ログイン"/>
            </footer>
          </form>
        </div>
      );
  }

  /**
   * 
   */
  handleChange = prop => event => {
    const data = this.state.data
    this.setState({ data: {
      ...data,
      [prop]: event.target.value,
    } })
  }

  /**
   * 
   */
  handleSubmitForm = (event) => {
    event.preventDefault();

    this.props.onSubmit(this.state.data)
  }
}

/**
 */
const DomainField = (props) => {
  const {
    value,
    onChange: handleChange,
   } = props

  return (
    <TextField
      id={ props.id }
      className={classNames(styles.DomainField, styles.TextField)}
      variant="outlined"
      type="text"
      label="DomainName"
      value={ props.value }
      onChange={ handleChange }
    />
  )
}

const UsernameField = props => {
  return (
    <TextField
      id={ props.id }
      className={classNames(styles.UsernameField, styles.TextField)}
      variant="outlined"
      type="text"
      label="Username"
      value={ props.value }
      onChange={ props.onChange }
      pattern="[a-z0-9._%+-]+"
    />
  )
}

class PasswordField extends TextField {
  state = {
    showPassword: false, 
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    return (
      <TextField
          id={ this.props.id }
          className={classNames(styles.PasswordField, styles.TextField)}
          variant="outlined"
          type={this.state.showPassword ? 'text' : 'password'}
          label="Password"
          value={ this.props.value }
          onChange={ this.props.onChange }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
    )
  }
}

export default Form
