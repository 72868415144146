"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PATH_ENV_JS = exports.PATH_LOGOUT = exports.PATH_LOGIN = void 0;
var PATH_LOGIN = '/login';
exports.PATH_LOGIN = PATH_LOGIN;
var PATH_LOGOUT = '/logout';
exports.PATH_LOGOUT = PATH_LOGOUT;
var PATH_ENV_JS = '/env.js';
exports.PATH_ENV_JS = PATH_ENV_JS;