import React, { Component } from 'react';
import { connect } from 'react-redux'

import { login } from '../../modules/Login/actions'
import styles from './styles.css';
import { Loading as LoadingImage } from '../../components'
import { Form } from './components/Form'
import { Complete } from './components/Complete'

/**
 */
export class Login extends Component {

  static defaultProps = {
    error: null,
    isLoading: false,
    username: '',
    domain: '',
    password: '',
  }

  renderStep() {
    if(this.props.isLoggedIn) {
      return (
        <Complete />
      )
    } else {
      return (
        <div className={ styles.Form }>
          <Form
            error={ this.props.error }
            domain={ this.props.domain }
            username={ this.props.username }
            password={ this.props.password }
            onSubmit={ this.props.onSubmit }
          />
        </div>
      )
    }
  }
  
  renderLoading() {
    if(this.props.isLoading) {
      return (<Loading />)
    }
    return ''
  }

  render() {
    const loading = this.renderLoading()
    const step = this.renderStep()
    
    return (
      <div className={ styles.Container }>
        <video className={ styles.Background } autoPlay loop muted>
          <source src="http://assets.vism.tv/movies/earth.mp4" type="video/mp4" />
        </video>
        { loading }
        { step }
      </div>
    );
  }
}

/**
 */
const Loading = (props) => (
  <div className={ styles.ContainerOverlay }>
    <div className={ styles.ContainerOverlayInner }>
      <LoadingImage height="500" width="500"/>
    </div>
  </div>
)

export default connect((state) => ({
  // mapStateToProps
  isLoading: state.login.isLoading,
  error: state.login.err,
  isLoggedIn: state.login.isLoggedIn,
}), (dispatch) => ({
  // mapDispatchToProps
  onSubmit: (data) => dispatch(login.request(data)),
}))(Login)

