import React, { Component } from 'react';

import envs from 'envs'

export class Complete extends Component {

  componentDidMount() {
    if(envs.REDIRECT_ON_LOGIN_SUCCESS) {
      window.location.href = envs.REDIRECT_ON_LOGIN_SUCCESS;
    }
  }

  render() {
    return (
      <div>
        Success 
      </div>
    )
  }
}
