import React, { Component } from 'react'

import Colored from './colored.svg'
import White from './white.svg'

/**
 */
export class LogoIcon extends Component {

  static defaultProps = {
    color: 'white',
    width: null,
    height: '14px',
  }

  render() {
    const iconProps = {
      width: this.props.width,
      height: this.props.height,
    }

    let path 
    switch(this.props.color) {
    case 'white':
      path = White
      break
    case 'colored':
    default:
      path = Colored
      break;
    }


    return (
      <>
        <img src={ path } alt="logo" { ...iconProps } height={ this.props.height } />
      </>
    ) 
  }
}

