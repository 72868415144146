import { handleActions } from 'redux-actions'
import * as actions from './types'

const debug = require('debug')('web:reducers:login:failure')

const defaultStates = {
  req:   null,
  res:   null,
  err:   null,
}

export default handleActions(
  {
    [actions.REQUEST]: (state, action) => {
      return {
        ...state,
        req:       {
          ...action.payload.req,
          password: '',
        },
        res:        null,
        err:        null,
        isLoading:  true,
        isLoggedIn: false,
      }
    },
    [actions.SUCCESS]: (state, action) => {
      return {
        ...state,
        res:        action.payload,
        isLoading:  false,
        isLoggedIn: true,
      }
    },
    [actions.FAILURE]: (state, action) => {
      let message = ''
      if (action && action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.message) {
        debug(action.payload.response)
        message = action.payload.response.data.message
      } else {
        debug(action)
        message = 'ネットワークエラー'
      }

      return {
        ...state,
        err:        message,
        isLoading:  false,
        isLoggedIn: false,
      }
    },
  }, 
  defaultStates,
  {
    prefix:   actions.PREFIX,
  })
