import React from 'react';
import ReactDOM from 'react-dom';
import './resources/global.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import { configureStore } from './store'

const store = configureStore()

ReactDOM.render(
  <Provider store={ store }>
    <App />
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

