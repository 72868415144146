import {
  applyMiddleware,
  createStore,
  combineReducers,
} from 'redux'

import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router'

import createSaga from 'redux-saga';

// Store components
import { history } from './history'
import { state as initialStates } from './init'
// Modules
import * as login from '../modules/Login'

function createReducers(history) {
  return combineReducers({
    router: connectRouter(history),
    login: login.reducers,
  })
}

function* rootSaga() {
  yield* login.sagas()
}

export const configureStore = () => {
  const saga  = createSaga()
  const store = createStore(
    createReducers(history),
    initialStates,
    applyMiddleware(
      routerMiddleware(history),
      saga,
    )
  )

  saga.run(rootSaga)
  
  return store
}
