import React from 'react'
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const BaseError = (props) => {
  return (
    <SnackbarContent
      aria-describedby="client-snackbar"
      className={ classNames(props.classes.error, props.className) }
      message={
        <span id="client-snackbar" className={props.classes.message}>
          <ErrorIcon className={classNames(props.classes.icon, props.classes.iconVariant)}/>
          { props.children }
        </span>
      }
    />
  )
}

export const Error = withStyles(styles)(BaseError);
